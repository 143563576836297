<script setup lang="ts">
import InputError from "@/Components/Form/InputError.vue";
import PrimaryButton from "@/Components/Form/PrimaryButton.vue";
import InputField from "@/Components/Form/InputField.vue";
import { Link, useForm } from "@inertiajs/vue3";
import ArrowRight from "@/Components/Icons/ArrowRight.vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";

const form = useForm({
    email: "",
});
</script>

<template>
    <BaseLayout class="flex flex-col items-center justify-center" :animationDuration="300">
        <div class="w-60 pb-12 pt-6 md:max-w-xs">
            <div class="text-md mb-12 font-bold uppercase text-gray-900">Willkommen zurück!</div>

            <form @submit.prevent="form.post(route('login.store'))">
                <label for="email" class="mb-5 block font-serif text-3xl">Wie lautet deine E-Mailadresse?</label>

                <div class="mb-4">
                    <InputField id="email" type="text" v-model="form.email" :error="!!form.errors.email" />
                    <InputError :message="form.errors.email" />
                </div>

                <PrimaryButton>Login</PrimaryButton>
            </form>

            <div class="mt-6">
                <Link class="group text-sm text-gray-700 hover:text-gray-900" :href="route('welcome.view')">
                    Benutzerkonto erstellen
                    <ArrowRight
                        class="inline h-4 w-4 text-gray-600 transition ease-in-out group-hover:translate-x-1 group-hover:text-gray-900"
                    />
                </Link>
            </div>
        </div>
    </BaseLayout>
</template>

<style scoped></style>